import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Anchor } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NextPage } from "next";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { LanguageSelector } from "../components/LanguageSelector";
import Page from "../components/Page";
import { WithSideNavigation } from "../components/WithSideNavigation/WithSideNavigation";
import { useEnterpriseId, useUsername } from "../hooks/useAuthGuard";
import { useTranslation } from "../i18n/useTranslation";
import { clearLocalStorage, clearSessionStorage } from "../utils/storageKeys";

const IndexPage: NextPage = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const username = useUsername();
  const enterpriseId = useEnterpriseId();

  useEffect(() => {
    if (username && enterpriseId) {
      router.push("/calendar");
    } else if (username && enterpriseId !== null && !enterpriseId) {
      router.push("/create-enterprise");
    }
  }, [username, enterpriseId, router]);

  const isMobile = useMediaQuery("(max-width: 450px)"); // Loosely based off of https://worship.agency/mobile-screen-sizes-for-2021

  return (
    <Page title="Landing page | KARL" description="Index">
      {isMobile ? (
        <Flex
          minHeight="100%"
          background="#3BA16B"
          color="white"
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="8vw"
          padding="8vw"
        >
          <img alt="Karl logo" src="/logo.png" width="50%" />
          <Text
            align="center"
            fontSize="7vw"
            fontWeight="bold"
            lineHeight="1.25"
          >
            {t("page.index.mobileWarning.Heading")}
          </Text>
          <Text align="center" fontSize="5vw" lineHeight="1.25">
            {t("page.index.mobileWarning.Paragraph")}
          </Text>
          <LanguageSelector />
        </Flex>
      ) : (
        <WithSideNavigation>
          <Flex
            grow={1}
            padding="20px"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            gap="20px"
            overflowY="auto"
          >
            {/* Spacer */}
            <Flex />

            <Flex direction="column" alignItems="center" gap="20px">
              <Heading as="h1" size="xl">
                {t("page.index.heading")}
              </Heading>

              <Button
                type="submit"
                variant="solid"
                onClick={() => {
                  clearLocalStorage();
                  clearSessionStorage();
                  signIn("cognito");
                }}
              >
                {t("page.index.login")}
              </Button>
            </Flex>

            <Flex direction="column" gap="10px" alignItems="center">
              <Anchor href="https://karl.farm/legal#terms" target="_blank">
                {t("page.profile.legal.termsAndConditions")}
              </Anchor>
              <Anchor href="https://karl.farm/legal#privacy" target="_blank">
                {t("page.profile.legal.privacyPolicy")}
              </Anchor>
            </Flex>
          </Flex>
        </WithSideNavigation>
      )}
    </Page>
  );
};

export default IndexPage;
